export function simplifyString(string){
    if (!string) return ''
    string=string.toLowerCase()
    let translates={'ä':'a','à':'a','â':'a','á':'a','ë':'e','é':'e','è':'e','ê':'e','ue':'u','oe':'o','ü':'u','ö':'o','ô':'o','ï':'i','ii':'i',
        'philippe':'filip','ph':'f','th':'t','sh':'s','sj':'s','gg':'g','gh':'g','ck':'k','ch':'k','c':'k','ks':'x','kx':'x','oha':'oa','ae':'aa','ou':'o','au':'o','uij':'ui','oij':'oi','aij':'ai','eij':'ei',
        'uy':'ui','ij':'ei','d':'t',
        'pp':'p','ll':'l','kk':'k','dd':'d','nn':'n','mm':'m','ss':'s','tt':'t','oo':'o','aa':'a','ee':'e','uu':'u','ng':'n','y':'i','ff':'f'} // just reduce all doubles to singles?
    let from; for (from in translates) string = string.replaceAll(from, translates[from]);
    string=string.replace(/[^a-z0-9 ]/g,'')
    string=string.replace(/ +/g,' ')
    // string=__cc.rtrim(st," \t\n\r\0\v")
    return string
}

export function toRelativeTime(timestamp){
    if(!timestamp) return '';
    const currentDate = new Date();
    const targetDate = new Date(timestamp*1000);
    const elapsed = currentDate - targetDate;
    const seconds = Math.floor(elapsed / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 7) {
        return targetDate.toLocaleDateString([], { day: 'numeric', month: 'long' }); // If more than a week, return the full date
    } else if (days > 1) {
        return `${days} days ago`;
    } else if (days === 1) {
        return 'Yesterday';
    } else if (hours >= 1) {
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else if (minutes >= 1) {
        return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    } else {
        return 'Just now';
    }
}
