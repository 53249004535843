import { useTranslation } from "react-i18next"
import simpleLogo from "../logo_simple.svg"
import logo from "../logo_white.svg"
import screenshot1 from "../screenshot1.png"
import background from "../nazaré.webp";
import backgroundDesktop from "../nazaré_landscape.webp";
import { Link } from "react-router-dom";


export default function Welcome(){
    const { t } = useTranslation();

    return (
        <>
            <div className="h-screen bg-cover bg-blend-darken md:hidden" style={{ backgroundImage: 'url('+background+')' }}>
                <div className="h-full bg-black bg-opacity-20 text-white p-8">
                    <img src={simpleLogo} alt="beyond boards logo" className="mx-auto fill-white my-20 w-1/3"/>
                    <p className="font-bold text-2xl text-center mb-16">{ t('Dive in! Join us for watersport adventures today.') }</p>
                    <Link to="/login">
                        <div className="w-full rounded shadow p-3 h-12 border flex justify-center whitespace-nowrap text-white font-bold bg-white bg-opacity-20 mb-4">
                            { t('Sign in') }
                        </div>
                    </Link>
                    <Link to="/register">
                        <div className="w-full rounded shadow p-3 h-12 border flex justify-center whitespace-nowrap text-white font-bold bg-white bg-opacity-20">
                            { t('Sign up') }
                        </div>
                    </Link>
                </div>
            </div>
            <div className="hidden md:block">
                <div className="bg-blue-100">
                    <nav className="fixed top-0 flex justify-between h-16 max-w-4xl mx-auto px-8 w-full">
                        <img src={logo} alt="beyond boards logo" className="h-2/3 mt-3"/>
                        {/* <ul className="flex">
                            <li>
                                <Link to="/welcome">
                                    <img src={logo} alt="beyond boards logo" className="h-2/3 mt-3 fill-white"/>
                                </Link>
                            </li>
                            <li>
                                <Link to="/about-us">About us</Link>
                            </li>
                        </ul>
                        <p>Download</p> */}
                    </nav>
                </div>
                <div className="bg-cover h-screen" style={{ backgroundImage: 'url('+backgroundDesktop+')' }}>
                    <div className="w-full h-full flex justify-center items-center bg-black bg-opacity-15">
                        <div className="">
                            <h1 className="text-center text-4xl font-bold text-white">{ t('Dive in! Join us for watersport adventures today.') }</h1>
                            <h2 className="text-center font-thin text-white">{ t('Beyond Boards, where watersports enthusiasts unite and adventure begins.') }</h2>
                        </div>
                    </div>
                </div>
                <div className="max-w-5xl mx-auto px-8">
                    <div className="flex mt-4">
                        <div className="w-1/2 flex items-center">
                            <p>{ t('Dive into a world where watersports enthusiasts like you connect, share, and make waves together. Whether you\'re into surfing, paddleboarding, boating, or diving, Beyond Boards is your gateway to a vibrant community of like-minded individuals.') }</p>
                        </div>
                        <div className="w-1/2 flex justify-center">
                            <img src={screenshot1} alt="app screenshot" className="h-2/3 mt-3"/>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div className="w-1/2">
                            <p>{ t('Create unforgettable experiences by hosting activities and adventures tailored to your passion. Whether you\'re a seasoned pro or just dipping your toes in, there\'s something for everyone.') }</p>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-1/2">
                            <p>{ t('Beyond the thrill of the ride, it\'s about the camaraderie of the community. Share tips, tricks, and tales with fellow water lovers who understand the call of the ocean.') }</p>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div className="w-1/2">
                            <p>{ t('Let\'s go beyond boards and explore the depths of our shared passion. Sign up now to join the Beyond Boards family and make a splash with us!') }</p>
                        </div>
                    </div>
                </div>
                <footer>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </footer>
            </div>
        </>
    )
}