export default function GDPR(){
    return (
        <div class="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
            <h1 class="text-3xl font-bold mb-6">GDPR Compliance Documentation</h1>
            <h2 class="text-2xl font-bold mb-4">1. Records of Processing Activities</h2>
            <p class="mb-4">We maintain detailed records of all data processing activities carried out by our app,
                including:</p>
            <ul class="list-disc ml-8 mb-4">
                <li>Types of personal data collected and processed</li>
                <li>Purposes of processing</li>
                <li>Data storage and retention periods</li>
                <li>Data sharing and transfers</li>
                <li>Security measures implemented</li>
            </ul>
            <h2 class="text-2xl font-bold mb-4">2. Data Protection Impact Assessments (DPIAs)</h2>
            <p class="mb-4">We conduct data protection impact assessments (DPIAs) to identify and mitigate risks to users'
                data privacy. DPIAs are performed for new data processing activities or significant changes to existing
                processes.</p>
            <h2 class="text-2xl font-bold mb-4">3. Data Processing Agreements</h2>
            <p class="mb-4">We have appropriate data processing agreements in place with third-party service providers
                involved in data processing activities on behalf of our app. These agreements outline the responsibilities
                and obligations of each party with respect to GDPR compliance.</p>
            <h2 class="text-2xl font-bold mb-4">4. Data Breach Notification Procedures</h2>
            <p class="mb-4">We have documented procedures in place to detect, investigate, and respond to data breaches in
                accordance with GDPR requirements. This includes notifying users and relevant supervisory authorities of
                breaches as required by law.</p>
            <h2 class="text-2xl font-bold mb-4">5. Data Protection Officer (DPO)</h2>
            <p class="mb-4">We have appointed a Data Protection Officer (DPO) responsible for overseeing our GDPR compliance
                efforts, monitoring compliance with data protection laws, and serving as a point of contact for data
                subjects and supervisory authorities.</p>
            <h2 class="text-2xl font-bold mb-4">6. Training and Awareness</h2>
            <p class="mb-4">We provide regular training and awareness programs for our staff members to ensure they
                understand their responsibilities under GDPR and are equipped to handle personal data in compliance with
                data protection regulations.</p>
        </div>
    )
}