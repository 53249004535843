import Icon from "../components/Icon"
import { Outlet, Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

export default function RootLayout() {
    const { user } = UserAuth();

    return (
        <>
            <main className="grow">
                <Outlet/>
            </main>
            <nav className="shadow-[3px_1px_3px_0_rgba(0,0,0,0.3)] flex justify-around p-3 bg-white stroke-gray-700 fill-gray-700 fixed bottom-0 w-full">
                <Link to="/" className="h-8 w-8">
                    <Icon name="home"/>
                </Link>
                <Link to="/events" className="h-8 w-8">
                    <Icon name="world"/>
                </Link>
                <Link to="/events/add" className="h-14 w-14 -mt-5 rounded-full bg-primary-gradient stroke-white hover:brightness-125 shadow p-2">
                    <Icon name="plus"/>
                </Link>
                <Link to={'/events?joined='+user.uid} className="h-8 w-8">
                    <Icon name="calendar"/>
                </Link>
                <Link to="/chats" className="h-8 w-8">
                    <Icon name="chat"/>
                </Link>
            </nav>
        </>
    );
}
