import { useEffect, useState } from "react"
import { sports } from "../utils/watersport";
import SportRadio from "./inputs/SportRadio";
import { simplifyString } from "../utils/misc";
import { useTranslation } from "react-i18next";
import { UserAuth } from "../context/AuthContext";
import LocationSearch from "./inputs/LocationSearch";
import { GeoPoint } from "firebase/firestore";
import { geohashForLocation } from "geofire-common";

export default function EventForm({ event={}, onEventChange, onSubmit }){
    const { user } = UserAuth();
    const { t } = useTranslation();

    const [ coverImage, setCoverImage ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ start, setStart ] = useState('');
    const [ end, setEnd ] = useState('');
    const [ sport, setSport ] = useState('');
    const [ locationString, setLocationString ] = useState('');
    const [ location, setLocation ] = useState({});
    const [ description, setDescription ] = useState('');

    useEffect(() =>{
        if(event.coverImage) setCoverImage(event.coverImage);
        if(event.title) setTitle(event.title);
        if(event.start){
            const startTzOffset = event.start.toDate().getTimezoneOffset() * 60000; //offset in milliseconds
            const startTime = event.start.toDate().getTime();
            setStart(new Date(startTime - startTzOffset).toISOString().slice(0, 16));
        }
        if(event.end){
            const endTzOffset = event.end.toDate().getTimezoneOffset() * 60000; //offset in milliseconds
            const endTime = event.end.toDate().getTime();
            setEnd(new Date(endTime - endTzOffset).toISOString().slice(0, 16));
        }
        if(event.sport) setSport(event.sport)
        if(event.location) setLocation(event.location)
        if(event.locationString) setLocationString(event.locationString)
        if(event.description) setDescription(event.description)
    },[event])

    useEffect(() => {
        const formData = {
            coverImage: coverImage,
            title: title,
            start: new Date(start),
            end: new Date(end),
            sport: sport,
            locationString: locationString,
            // location: new GeoPoint(location?.coords?.lat, location?.coords?.lng),
            description: description,
            search: simplifyString(title),
            creator: user.uid
        };

        if(location.coords){
            const geopoint = new GeoPoint(location.coords.lat, location.coords.lng)
            formData.location = geopoint
            formData.geoHash = geohashForLocation([location.coords.lat, location.coords.lng])
        }

        onEventChange(formData);
    },[coverImage, title, start, end, sport, location, locationString, description])

    return (
        <form className="p-4 pt-0 mb-16" onSubmit={onSubmit}>
            <label htmlFor="coverImage" className="label">{ t('Cover image') }</label>
            <input name="coverImage" id="coverImage" type="file" className="input" onChange={e => setCoverImage(e.target.files[0])}/>

            <label htmlFor="title" className="label">{ t('Title') }</label>
            <input name="title" id="title" className="input" value={title} onChange={e => setTitle(e.target.value)}/>

            <label htmlFor="start" className="label">{ t('Start') }</label>
            <input name="start" id="start" placeholder="Start" type="datetime-local" className="input" value={start} onChange={e => setStart(e.target.value)}/>

            <label htmlFor="end" className="label">{ t('End') }</label>
            <input name="end" id="end" placeholder="End" type="datetime-local" className="input" value={end} onChange={e => setEnd(e.target.value)}/>

            <label htmlFor="sport" className="label">{ t('Sport') }</label>
            <div className="grid grid-cols-3 gap-2 mb-4">
                { sports.map(watersport => <SportRadio key={watersport} sport={watersport} value={sport} onChange={e => setSport(e.target.value)}/>) }
            </div>

            <label htmlFor="location" className="label">{ t('Location') }</label>
            <LocationSearch name="location" id="location" value={locationString} onChangeLocationString={e => setLocationString(e.target.value)} onChangeLocation={e => setLocation(e.target.value)}/>
            {/* <input name="location" id="location" className="input" value={locationString} onChange={e => setLocationString(e.target.value)}/> */}

            <label htmlFor="description" className="label">{ t('Description') }</label>
            <textarea name="description" id="description" className="input min-h-36" value={description} onChange={e => setDescription(e.target.value)}></textarea>

            <button type="submit" className="submit-button text-white">{ t('Save') }</button>
        </form>
    )

}