'use client'

import { useEffect, useState } from "react";
import StickyHeader from "../components/StickyHeader";
import { useNavigate, useParams } from "react-router-dom";
import SwitchInput from "../components/inputs/SwitchInput";
import { useTranslation } from "react-i18next";
import { getDeviceId, removeMessagingDeviceToken, saveMessagingDeviceToken } from "../utils/messaging";
import { UserAuth } from "../context/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";

export default function EditEvent() {
    const { t } = useTranslation();
    const { user } = UserAuth();
    const [isOn, setIsOn] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);

    function handleToggle() {
        setIsOn(!isOn);
    }

    useEffect(function () {
        const deviceId = getDeviceId();
        const deviceRef = doc(db, 'users', user.uid,'devices',deviceId);
        getDoc(deviceRef)
            .then((deviceSnap) => {
                if(deviceSnap.exists()){
                    const deviceData = deviceSnap.data();
                    if(deviceData.fcmToken) setIsOn(true);
                }
                setPageLoaded(true);
            })
    },[])

    useEffect(() => {
        if (pageLoaded) {
            if (isOn) saveMessagingDeviceToken(user.uid)
            else removeMessagingDeviceToken(user.uid)
        }
    }, [isOn])

    return (
        <>
            <StickyHeader title="Settings" />
            <form className="p-4 text-gray-700">
                <div className="flex justify-between">
                    <label>{t('Allow notifications')}</label>
                    <SwitchInput isOn={isOn} handleToggle={handleToggle} />
                </div>
            </form>
        </>
    );
}
