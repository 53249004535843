
import logo from "../logo.svg"
import Icon from "../components/Icon"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { UserAuth } from '../context/AuthContext'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"

export default function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, emailSignIn, signUpWithGoogle } = UserAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if(user) navigate('/');
    },[user])

    function loginUser(e){
        e.preventDefault();
        if(!email || !password) return;
        emailSignIn(email, password)
            .then(() => {
                navigate('/');
            })
            .catch((error) => {
                console.log(error)
            });
    }

    function googleLogin(e){
        e.preventDefault();
        signUpWithGoogle()
            .then(() => {
                navigate('/');
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className="h-screen flex flex-col justify-center">
            <div>
                <img src={logo} alt="beyond boards logo" className="mx-auto mb-16 w-1/2"/>
            </div>
            <div className="px-8">
                <form onSubmit={loginUser}>
                    <p className="font-bold text-gray-500 mb-4 text-lg">{ t('Login to your account') }</p>
                    <input id="email" name="email" type="email" placeholder={ t('Email') } value={email} onChange={e => setEmail(e.target.value)} className="w-full shadow rounded outline-none p-3 mb-4"/>
                    <input id="password" name="password" type="password" placeholder={ t('Password') } value={password} onChange={e => setPassword(e.target.value)} className="w-full shadow rounded outline-none p-3 mb-4"/>
                    <button type="submit" className="w-full shadow rounded outline-none p-3 mb-4 bg-primary text-white hover:brightness-125 mt-6">{ t('Sign in') }</button>
                </form>
                <div className="flex justify-between">
                    <button onClick={googleLogin} className="w-full rounded shadow p-3 h-12 border flex justify-center whitespace-nowrap text-gray-500 font-bold">
                        <div className="w-6 h-6 mr-4">
                            <Icon name="google"/>
                        </div>
                        { t('Continue with Google') }
                    </button>
                    {/* <button className="w-1/3 rounded shadow p-3 h-12 mx-2">
                        <Icon name="facebook"/>
                    </button>
                    <button className="w-1/3 rounded shadow p-3 h-12">
                        <Icon name="google"/>
                    </button> */}
                </div>
            </div>
            <p className="text-center text-gray-500 mt-16 w-full">{ t('Don\'t have an account?') } <Link to="/register" className="text-primary">{ t('Sign up') }</Link></p>
        </div>
    );
}
