import { db } from "../utils/firebase";
import { addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { getCoverImage } from "../utils/watersport";
import { getJoinedEventIds } from "./event";

export async function getChatGroups(user){
    const chatsRef=collection(db, "chats");

    const chatRoomIds = await getJoinedEventIds(user);

    const q = query(chatsRef,where('__name__','in',chatRoomIds));
    const querySnapshot = await getDocs(q);

    let chats=[];
    querySnapshot.forEach(async (doc) => {
        const chat = doc.data();
        chat.id = doc.id;
        chats.push(chat);
    });

    return chats;
}

export async function getChatGroup(chatId){
    const chatRef = doc(db, 'chats', chatId);
    const chatSnap = await getDoc(chatRef);

    if(chatSnap.exists()) return chatSnap.data();

    const eventRef = doc(db, 'events', chatId);
    const eventSnap = await getDoc(eventRef);

    const event = eventSnap.data();

    const chatGroup = {
        name: event.title,
        coverImage: getCoverImage(event)
    }
    setDoc(chatRef,chatGroup);

    return chatGroup;
}

export function useChatCollectionData(chatId){
    const messagesRef = collection(db, 'chats', chatId, 'messages');
    const q = query(messagesRef, orderBy('createdAt'),limit(25));

    return useCollectionData(q, {idField: 'id'});
}

export function sendMessage(chatId, user, message){
    const { uid, photoURL } = user;

    updateDoc(doc(db, 'chats', chatId),{
        lastMessage: new Date(),
        lastMessageText: message
    });

    addDoc(collection(db, 'chats', chatId, 'messages'), {
        text: message,
        createdAt: new Date(),
        uid,
        photoURL
    });
}