export default function Loading(){
    return (
        <div className="h-screen flex flex-col justify-center items-center">
            {/* <div className="loader-wrapper mx-auto my-auto">
                <div className="loader"></div>
            </div> */}
            {/* <br></br> */}
            <div className="logo fill-primary">
                <svg width="100" height="100" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_8_86" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="5" y="9" width="11" height="7">
                        <path d="M5.08212 9.56311H15.7488V15.7714H5.08212V9.56311Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_8_86)">
                        <path d="M8.42021 9.66432C9.89878 9.63932 11.3809 9.62503 12.8583 9.67741C14.4738 9.73575 15.6654 10.9798 15.7345 12.5822C15.8024 14.1262 14.6333 15.6167 13.0547 15.675C10.5214 15.7691 7.98093 15.7107 5.3595 15.7036L8.42021 9.66432Z"/>
                    </g>
                    <mask id="mask1_8_86" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="9" y="1" width="7" height="8">
                        <path d="M9.04404 1.63928H16V8.03928H9.04404V1.63928Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask1_8_86)">
                        <path d="M12.6512 7.90232C11.5643 7.90947 10.475 7.90471 9.31189 7.90351L12.3893 1.82614C14.1202 1.6809 15.6774 2.93566 15.8071 4.62375C15.9488 6.46304 14.5821 7.88804 12.6512 7.90232Z"/>
                    </g>
                    <mask id="mask2_8_86" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="11" height="7">
                        <path d="M0.205963 0H10.8726V6.21071H0.205963V0Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask2_8_86)">
                        <path d="M7.52977 6.10239C6.0512 6.12739 4.56906 6.14168 3.09168 6.0893C1.4762 6.03097 0.284534 4.78692 0.215486 3.18454C0.147629 1.64049 1.31668 0.150015 2.89406 0.0916821C5.42858 -0.00236557 7.96786 0.055968 10.5893 0.0631107L7.52977 6.10239Z"/>
                    </g>
                    <mask id="mask3_8_86" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="7" width="7" height="8">
                        <path d="M0 7.7345H6.91071V14.1345H0V7.7345Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask3_8_86)">
                        <path d="M3.29881 7.8642C4.38572 7.85706 5.475 7.86182 6.6381 7.86301L3.55953 13.9404C1.82858 14.0856 0.271433 12.8309 0.142861 11.1428C0.00119457 9.30349 1.36786 7.87849 3.29881 7.8642Z"/>
                    </g>
                </svg>
            </div>

        </div>
    )
}