export default function PrivacyPolicy(){
    return (
        <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
            <p className="mb-4">This Privacy Policy ("Policy") describes how Apptiek ("we," "us," or "our") collects, uses, and shares personal information collected from users ("you" or "your") of the Beyond boards application ("App"). By using the App, you agree to the collection and use of information in accordance with this Policy.</p>
            <h2 className="text-2xl font-bold mb-4">1. Information We Collect</h2>
            <p className="mb-4">1.1. <strong>Information You Provide:</strong> When you create an account or interact with the App, we may collect personal information such as your name, email address, profile picture, and any other information you choose to provide.</p>
            <p className="mb-4">1.2. <strong>Activity Information:</strong> When you post or join water sports activities ("Activities") through the App, we may collect information related to those activities, such as location, date, time, and any additional details provided by you or other users.</p>
            <p className="mb-4">1.3. <strong>Usage Information:</strong> We may collect information about your interactions with the App, including the pages you view, the features you use, and other actions you take within the App.</p>
            <p className="mb-4">1.4. <strong>Device Information:</strong> We may collect information about the device you use to access the App, including device type, operating system, unique device identifiers, and mobile network information.</p>
            <h2 className="text-2xl font-bold mb-4">2. How We Use Your Information</h2>
            <p className="mb-4">2.1. We may use the information we collect for various purposes, including to:</p>
            <ul className="list-disc ml-4 mb-4">
                <li>Provide and maintain the App's functionality.</li>
                <li>Personalize your experience and deliver targeted content.</li>
                <li>Facilitate communication between users regarding Activities.</li>
                <li>Analyze usage trends and improve the App's performance.</li>
                <li>Comply with legal obligations and enforce our Terms and Conditions.</li>
            </ul>
            <h2 className="text-2xl font-bold mb-4">3. How We Share Your Information</h2>
            <p className="mb-4">3.1. We may share your personal information in the following circumstances:</p>
            <ul className="list-disc ml-4 mb-4">
                <li>With other users to facilitate participation in Activities.</li>
                <li>With service providers who assist us in operating the App and providing related services.</li>
                <li>In response to legal requests or to protect our rights or the rights of others.</li>
            </ul>
            <p className="mb-4">3.2. We will not sell, rent, or otherwise disclose your personal information to third parties for their marketing purposes without your consent.</p>
            <h2 className="text-2xl font-bold mb-4">4. Data Retention</h2>
            <p className="mb-4">4.1. We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law.</p>
            <h2 className="text-2xl font-bold mb-4">5. Security</h2>
            <p className="mb-4">5.1. We take reasonable measures to protect the security of your personal information and to prevent unauthorized access, disclosure, alteration, or destruction of data.</p>
            <h2 className="text-2xl font-bold mb-4">6. Your Choices</h2>
            <p className="mb-4">6.1. You may update or correct your account information at any time by accessing your account settings within the App.</p>
            <p className="mb-4">6.2. You may also choose to delete your account, in which case we will permanently delete your personal information, subject to applicable legal requirements.</p>
            <h2 className="text-2xl font-bold mb-4">7. Children's Privacy</h2>
            <p className="mb-4">7.1. The App is not intended for use by children under the age of 17. We do not knowingly collect personal information from children under 17 years of age. If you believe that we have inadvertently collected information from a child under 17, please contact us immediately.</p>
            <h2 className="text-2xl font-bold mb-4">8. Changes to this Policy</h2>
            <p className="mb-4">8.1. We reserve the right to update or change this Policy at any time. If we make material changes to this Policy, we will notify you by posting the revised Policy on the App or by other means as required by law.</p>
            <h2 className="text-2xl font-bold mb-4">9. Contact Us</h2>
            <p className="mb-4">9.1. If you have any questions about this Policy or our privacy practices, please contact us at <a href="mailto:woutvanwonterghem@gmail.com" className="text-primary">woutvanwonterghem@gmail.com</a>.</p>
        </div>
    )
}