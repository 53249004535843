export default function ChatMessage({ message, user }){
    const isOwnMessage=message.uid===user.uid;
    return (
        <div className={'flex my-1 '+(isOwnMessage ? 'justify-end' : 'justify-start')}>
            <div className={'flex '+(isOwnMessage && 'flex-row-reverse')}>
                <img src={message.photoURL} className="rounded-full h-6 w-6"/>
                <div className="bg-gray-100 rounded py-1 px-2 mx-2">
                    <p>{ message.text }</p>
                </div>
            </div>
        </div>
    )
}