export const sports=['Surfing','Supping','Wakeboarding','Waterskiing','Diving','Jetsking','Offshore racing','Procat racing','Sailing'];

export const defaultImages={
    Surfing: 'https://media.cnn.com/api/v1/images/stellar/prod/130624174959-50-surf-spots-the-box.jpg?q=w_2365,h_1571,x_0,y_0,c_fill/h_618',
    Supping: 'https://mh.co.za/wp-content/uploads/2022/08/The-Benefits-Of-Stand-Up-Paddling.-Plus-The-Best-Supping-Destinations.jpg',
    Wakeboarding: 'https://d2v1xobx8qu3lm.cloudfront.net/media/mageplaza/blog/post/j/a/jack-van-tricht-izwepu8aqbc-unsplash.jpg',
    Waterskiing: 'https://d2v1xobx8qu3lm.cloudfront.net/media/mageplaza/blog/post/j/a/jack-van-tricht-izwepu8aqbc-unsplash.jpg',
    Diving: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/07/2d/d7/09.jpg',
    'Offshore racing':'https://www.speedonthewater.com//images/2019_Images/222racing/222racing-02.jpg',
    'Procat racing':'https://scontent.fbru4-1.fna.fbcdn.net/v/t31.18172-8/10474517_655880141201604_2157240215501382618_o.jpg?_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=zOhj3iodgPQAX_qqzIO&_nc_ht=scontent.fbru4-1.fna&oh=00_AfDoR6K_603CSA3-FXX4zvpWrXtJCOt7F4kI53OfJMAaYg&oe=661E711C',
    Sailing: 'https://d3mvlb3hz2g78.cloudfront.net/wp-content/uploads/2018/11/thumb_720_450_dreamstime_l_99978767.jpg',
    Jetsking: 'https://d3mvlb3hz2g78.cloudfront.net/wp-content/uploads/2018/11/thumb_720_450_dreamstime_l_99978767.jpg',
}

export function getCoverImage(event){
    if(event.coverImage) return event.coverImage
    if(event?.location?.latitude) return 'https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/'+event.location.longitude+','+event.location.latitude+',12,0.00,0.00/1000x600@2x?access_token=pk.eyJ1Ijoid291dHZ3IiwiYSI6ImNsa2ZydzBqZTFubnozbHRqdWkzMDlvdHQifQ.Zt44DMvrSnoETzMCE3oOBA'
    return defaultImages[event.sport]
}