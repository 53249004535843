export default function SkeletonEventCard(){
    return (
        <div className="bg-white rounded-2xl shadow flex flex-col justify-between w-full">
            <div className="animate-pulse">
                <div className="h-40 rounded-t-2xl w-full bg-gray-300"></div>
                <div className="p-2 mb-4">
                    <div className="w-32 h-4 bg-gray-300 rounded-full mt-2"></div>
                    <div className="w-44 h-4 bg-gray-300 rounded-full mt-2"></div>
                    <div className="w-24 h-3 bg-gray-300 rounded-full mt-3"></div>
                    <div className="w-28 h-4 bg-gray-300 rounded-full mt-4"></div>
                </div>
            </div>
        </div>
    )
}