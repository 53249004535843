'use client'

import { Link } from "react-router-dom";
import Icon from "../components/Icon";
import { UserAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { cleanupOldEvents } from "../models/event";

export default function Profile() {
    const { t } = useTranslation();
    const { user, logOut } = UserAuth();

    return (
        <div className="p-4">
            <img className="rounded-full w-32 h-32 object-cover mx-auto mt-4" src={user.photoURL} alt={ user.displayName }/>
            <p className="text-center font-bold mt-4">{ user.displayName }</p>
            <p className="text-center text-gray-500 font-light">{ t('Last activity') } 10:35</p>
            <p className="font-bold mt-4">{ t('Actions') }</p>
            <div className="rounded-lg border text-gray-700 stroke-gray-700 mb-4">
                <Link to="/profile/edit" className="flex justify-between p-4 items-center">
                    <p>{ t('Edit profile') }</p>
                    <div className="h-10 w-10 stroke-gray-500 rotate-180">
                        <Icon name="back-arrow"/>
                    </div>
                </Link>
                <Link to="/settings" className="flex justify-between p-4 items-center">
                    <p>{ t('Settings') }</p>
                    <div className="h-10 w-10 stroke-gray-500 rotate-180">
                        <Icon name="back-arrow"/>
                    </div>
                </Link>
                <Link to={'/events?uid='+user.uid} className="flex justify-between p-4 items-center">
                    <p>{ t('My activities') }</p>
                    <div className="h-10 w-10 stroke-gray-500 rotate-180">
                        <Icon name="back-arrow"/>
                    </div>
                </Link>
                <Link to={'/events?joined='+user.uid} className="flex justify-between p-4 items-center">
                    <p>{ t('Joined activities') }</p>
                    <div className="h-10 w-10 stroke-gray-500 rotate-180">
                        <Icon name="back-arrow"/>
                    </div>
                </Link>
            </div>
            <div className="rounded-lg border text-gray-700 stroke-gray-700 mb-4">
                <Link to="/terms-and-conditions" className="flex justify-between p-4 items-center">
                    <p>{ t('Terms & Conditions') }</p>
                    <div className="h-10 w-10 stroke-gray-500 rotate-180">
                        <Icon name="back-arrow"/>
                    </div>
                </Link>
                <Link to="/privacy-policy" className="flex justify-between p-4 items-center">
                    <p>{ t('Privacy policy') }</p>
                    <div className="h-10 w-10 stroke-gray-500 rotate-180">
                        <Icon name="back-arrow"/>
                    </div>
                </Link>
                <Link to="gdpr" className="flex justify-between p-4 items-center">
                    <p>{ t('GDPR compliance') }</p>
                    <div className="h-10 w-10 stroke-gray-500 rotate-180">
                        <Icon name="back-arrow"/>
                    </div>
                </Link>
            </div>
            {
                user.uid === 'QK4vDmopethkIGSryE5w1sDTO8B3' && (
                    <div className="rounded-lg border text-gray-700 stroke-gray-700">
                        <button onClick={() => cleanupOldEvents()} className="w-full flex justify-between p-4 items-center">
                            <p>{ t('Remove old events') }</p>
                            <div className="h-10 w-10 stroke-gray-500 rotate-180">
                                <Icon name="back-arrow"/>
                            </div>
                        </button>
                    </div>
                )
            }
            <div className="flex justify-between mt-4">
                <button className="text-center mx-auto text-red-500 stroke-red-500 flex items-center" onClick={logOut}>
                    <div className="h-10 w-10 mr-4">
                        <Icon name="signout"/>
                    </div>
                    { t('Log out') }
                </button>
            </div>
            <p className="text-gray-500 text-center text-sm mt-4 mb-16">{ t('For issues or questions mail:') } <a href="mailto:woutvanwonterghem@gmail.com" className="underline">woutvanwonterghem@gmail.com</a></p>
        </div>
    );
}
