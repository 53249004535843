'use client'

import { useEffect, useState } from "react";
import EventForm from "../components/EventForm";
import StickyHeader from "../components/StickyHeader";
import { getEvent, updateEvent } from "../models/event";
import { useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../utils/firebase";

export default function EditEvent() {
    const navigate = useNavigate();
    const params = useParams();

    const [ event, setEvent ] = useState({});
    const [ formData, setFormData ] = useState({});

    useEffect(() => {
        getEvent(params.id)
            .then(event => {
                // if(event.start) event.start = event.start.toDate().toISOString().slice(0, 16)
                // if(event.end) event.end = event.end.toDate().toISOString().slice(0, 16)
                setEvent(event);
                // setEventId(event.id);
                // setTitle(event.title);
                // setStart(event.start.toDate().toISOString().slice(0, 16));
                // setEnd(event.end.toDate().toISOString().slice(0, 16));
                // setSport(event.sport);
                // setLocation(event.location);
                // setDecription(event.description);
            })
    },[params.id])

    async function submitForm(e){
        e.preventDefault();

        if(formData?.coverImage?.name){
            const imageRef = ref(storage, `/coverImages/${formData.coverImage.name}`);
            const r = await uploadBytes(imageRef, formData.coverImage)
            const url = await getDownloadURL(r.ref)
            formData.coverImage=url;
        }

        await updateEvent(event.id, formData)
        navigate(-1)
    }

    function onEventChange(formData){
        setFormData(formData);
    }

    return (
        <>
            <StickyHeader title="Edit activity"/>
            <EventForm event={event} onEventChange={onEventChange} onSubmit={submitForm}/>
        </>
    );
}
