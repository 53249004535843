import { useTranslation } from "react-i18next"

export default function About(){
    const { t } = useTranslation();
    return (
        <>
            <p className="font-bold text-2xl text-gray-500">{ t('Welcome to Beyond Boards!') }</p>
            <p className="my-4">{ t('Dive into a world where watersports enthusiasts like you connect, share, and make waves together. Whether you\'re into surfing, paddleboarding, boating, or diving, Beyond Boards is your gateway to a vibrant community of like-minded individuals.') }</p>
            <p className="my-4">{ t('Create unforgettable experiences by hosting activities and adventures tailored to your passion. Whether you\'re a seasoned pro or just dipping your toes in, there\'s something for everyone.') }</p>
            <p className="my-4">{ t('Beyond the thrill of the ride, it\'s about the camaraderie of the community. Share tips, tricks, and tales with fellow water lovers who understand the call of the ocean.') }</p>
            <p className="my-4">{ t('Let\'s go beyond boards and explore the depths of our shared passion. Sign up now to join the Beyond Boards family and make a splash with us!') }</p>
            <p>{ t('Sign up and dive in.') }</p>
        </>
    )
}