import Icon from "../Icon";

export default function SportRadio({ sport, value, onChange }){
    function handleClick(e){
        e.preventDefault();
        e.target.value=sport;
        onChange(e);
    }
    return (
        <button type="button" className={'border border-gray-200 rounded p-1 h-28 '+(value===sport?'fill-primary text-primary':'fill-gray-500 text-gray-500')} value={sport} onClick={handleClick}>
            <div className="w-10 h-10 mx-auto my-2">
                <Icon name={sport}/>
            </div>
            <p className="font-bold text-sm">{ sport }</p>
        </button>
    )
}