import { getToken } from "firebase/messaging";
import { db, messaging } from "./firebase";
import { deleteField, doc, setDoc, updateDoc } from "firebase/firestore";

const VAPID_KEY = 'BB5Jd_kwMKhNpW1ustEU7U7mnT-2wu__ftOZ_TESDfMPOMladyqhK0DwifoH7VukqWnuhR08XPxLTAM0P-DIOPs';

export async function removeMessagingDeviceToken(uid) {
    console.log('removing device token')
    const deviceId = getDeviceId();
    const deviceRef = doc(db, 'users', uid,'devices',deviceId);
    await updateDoc(deviceRef, {
        fcmToken: deleteField()
    });
}

export async function saveMessagingDeviceToken(uid) {
    const fcmToken = await getToken(messaging, { vapidKey: VAPID_KEY });
    if (fcmToken) {
        console.log('Got FCM token: ' + fcmToken)
        const deviceId = getDeviceId();
        const deviceRef = doc(db, 'users', uid,'devices',deviceId);
        await setDoc(deviceRef, { fcmToken })
    } else {
        console.log('No registration token available. Request permission to generate one.');
        requestNotificationPermissions(uid);
    }
}

export async function requestNotificationPermissions(uid) {
    console.log('Requesting notifications permissions ...');
    const permissions = await Notification.requestPermission();

    if(permissions==='granted') {
        saveMessagingDeviceToken(uid);
    }else{
        console.log('unable to get permissions')
    }
}

export function getDeviceId() {
    let deviceId = localStorage.getItem('device_id');

    if (!deviceId) {
        deviceId = (Math.random() + 1).toString(36).substring(7);
        localStorage.setItem('device_id', deviceId);
    }

    return deviceId;
}