// import { getToken } from "firebase/messaging";
// import { messaging } from "./utils/firebase";

// const VAPID_KEY = 'BB5Jd_kwMKhNpW1ustEU7U7mnT-2wu__ftOZ_TESDfMPOMladyqhK0DwifoH7VukqWnuhR08XPxLTAM0P-DIOPs';

if('serviceWorker' in navigator){
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(registration=>{
            console.log('messaging service worker registered');
        })
    // navigator.serviceWorker.register('/sw.js', { scope: '/' })
    //     .then(registration => {
    //         Notification.requestPermission().then(permission => {
    //             if(permission==='denied') return;
    //             getToken(messaging, { serviceWorkerRegistration: registration, vapidKey: VAPID_KEY })
    //                 .then((currentToken) => {
    //                     if (currentToken) {
    //                         console.log(currentToken)
    //                         // Send the token to your server and update the UI if necessary
    //                         // ...
    //                     } else {
    //                         // Show permission request UI
    //                         console.log('No registration token available. Request permission to generate one.');
    //                         // ...
    //                     }
    //                 })
    //                 .catch((err) => {
    //                     console.log('An error occurred while retrieving token. ', err);
    //                     // ...
    //                 });
    //         })
    //     })

    // navigator.serviceWorker.ready
    //     .then(registration => {
    //         console.log('Service worker ready', registration)
    //     })
}