import { Link, useNavigate } from "react-router-dom";
import Icon from "./Icon";
import { useTranslation } from "react-i18next";

export default function StickyHeader({ title, to }){
    const { t } = useTranslation();
    const navigate = useNavigate();

    function BackButton(){
        if(to) return (
            <Link to={to} className="bg-white rounded-full w-8 h-8 m-2 stroke-gray-500 absolute -top-1 left-0">
                <Icon name="back-arrow"/>
            </Link>
        )
        return (
            <button type="button" className="bg-white rounded-full w-8 h-8 m-2 stroke-gray-500 absolute -top-1 left-0" onClick={() => navigate(-1)}>
                <Icon name="back-arrow"/>
            </button>
        )
    }

    return (
        <div className="sticky top-0 bg-white h-24 flex flex-col justify-center shadow z-10">
            <div className="flex justify-center relative">
                <BackButton/>
                <h1 className="font-bold text-xl text-gray-700 text-center">{ t(title) }</h1>
            </div>
        </div>
    )
}