'use client'

import { useState } from "react";
import { storeEvent } from "../models/event";
import { useNavigate } from "react-router-dom";
import EventForm from '../components/EventForm';
import StickyHeader from "../components/StickyHeader";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../utils/firebase";

export default function Home() {
    const navigate = useNavigate();

    const [ formData, setFormData ] = useState({});

    async function submitForm(e){
        e.preventDefault();

        if(formData.coverImage){
            const imageRef = ref(storage, `/coverImages/${formData.coverImage.name}`);
            const r = await uploadBytes(imageRef, formData.coverImage)
            const url = await getDownloadURL(r.ref)
            formData.coverImage=url;
        }

        const id = await storeEvent(formData);
        navigate('/events/'+id);
    }

    return (
        <>
            <StickyHeader title="Create an activity" to="/"/>
            <EventForm onEventChange={data => setFormData(data)} onSubmit={submitForm}/>
        </>
    );
}
