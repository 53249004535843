import Icon from "../components/Icon";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { UserAuth } from "../context/AuthContext";
import ChatMessage from "../components/ChatMessage";
import StickyHeader from "../components/StickyHeader";
import { useChatCollectionData, sendMessage, getChatGroup } from "../models/chat";

export default function Chat(){
    const { t } = useTranslation();
    const { user } = UserAuth();
    const params = useParams();

    const [ messages ] = useChatCollectionData(params.id);

    const [ chatGroup, setChatGroup ] = useState({});
    const [ message, setMessage ] = useState('');

    const [ pageHeight, setPageHeight ] = useState(null);

    useEffect(() => {
        if (window.visualViewport) setPageHeight(window.visualViewport.height)
        window.addEventListener('resize', () => {
            if (window.visualViewport) setPageHeight(window.visualViewport.height)
        })

        getChatGroup(params.id)
            .then((chatGroup) => setChatGroup(chatGroup))
    },[])

    async function send(e){
        e.preventDefault();
        sendMessage(params.id, user, message);
        setMessage('');
    }

    return (
        <div style={{height: pageHeight}} className="flex flex-col relative">
            <StickyHeader title={ chatGroup.name }/>
            <div className="grow">
                <div className="pt-2 px-2">
                    { messages && messages.map((message,idx) => ( <ChatMessage key={idx} message={message} user={user}/> )) }
                </div>
                <form onSubmit={send} className="absolute bottom-0 w-full flex p-4 pb-0 bg-white shadow-[3px_1px_3px_0_rgba(0,0,0,0.3)]">
                    <input placeholder={ t('Write your message') } value={message} onChange={e => setMessage(e.target.value)} className="input h-10"/>
                    <button type="submit" className="bg-primary mb-4 rounded ml-2 w-12 h-10 stroke-white p-1">
                        <Icon name="envelope"/>
                    </button>
                </form>
            </div>
        </div>
    )
}