'use client'

import { useState } from "react";
import { updateProfile } from "firebase/auth";
import { auth, storage } from "../utils/firebase";
import { UserAuth } from "../context/AuthContext";
import StickyHeader from "../components/StickyHeader";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function EditProfile() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = UserAuth();

    const [ profileImage, setProfileImage ] = useState('');
    const [ name, setName ] = useState(user.displayName??'');

    async function submitForm(e){
        e.preventDefault();
        let url = user.photoURL;

        if(profileImage){
            const imageRef = ref(storage, `/profileImages/${user.uid}`);
            const r = await uploadBytes(imageRef, profileImage)
            url = await getDownloadURL(r.ref)
        }

        updateProfile(auth.currentUser, {
                displayName: name,
                photoURL: url,
            }).then( () => {
                navigate(-1)
            })
    }

    return (
        <>
            <StickyHeader title="Edit profile"/>
            <form className="p-4 pt-0" onSubmit={submitForm}>
                <img className="rounded-full w-32 h-32 object-cover mx-auto mt-4" src={ user.photoURL } alt={ user.displayName }/>
                <label htmlFor="profileImage" className="label">{ t('Profile picture') }</label>
                <input name="profileImage" id="profileImage" type="file" className="input" onChange={e => setProfileImage(e.target.files[0])}/>

                <label htmlFor="name" className="label">{ t('Name') }</label>
                <input name="name" id="name" className="input" value={name} onChange={e => setName(e.target.value)}/>

                <button type="submit" className="submit-button text-white">{ t('Save') }</button>
            </form>
        </>
    );
}
