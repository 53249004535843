import { useEffect, useState } from "react";
import EventCard from "./EventCard";
import SkeletonEventCard from "./SkeletonEventCard";

export default function EventsList({events}){
    const [ eventsArr, setEventsArr ] = useState([{id:1, skeleton:true}, {id:2, skeleton:true}, {id:3, skeleton:true}])

    useEffect(() => {
        if(events.length) setEventsArr(events)
    },[events])

    return (
        <div className="flex overflow-auto no-scrollbar pb-2">
            {eventsArr.map(event =>{
                if(event.skeleton) return (<div key={event.id} className="min-w-56 w-56 mx-2"><SkeletonEventCard/></div>)
                return (<div key={event.id} className="min-w-56 w-56 mx-2"><EventCard event={event}/></div>);
            })}
        </div>
    )
}