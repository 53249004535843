export function TermsAndConditions(){
    return (
        <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
            <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
            <p className="mb-4">These Terms and Conditions ("Terms") govern your access to and use of the Beyond boards application ("App") and any related services provided by Apptiek ("we," "us," or "our"). By accessing or using the App, you agree to comply with these Terms. If you do not agree with these Terms, you may not access or use the App.</p>
            <h2 className="text-2xl font-bold mb-4">1. Account Registration</h2>
            <p className="mb-4">1.1. In order to use certain features of the App, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information updated.</p>
            <p className="mb-4">1.2. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account. You agree to notify us immediately of any unauthorized access to or use of your account.</p>
            <h2 className="text-2xl font-bold mb-4">2. Posting and Joining Activities</h2>
            <p className="mb-4">2.1. The App allows users to create and post water sports activities ("Activities"). By posting an Activity, you represent and warrant that you have all necessary rights and permissions to do so.</p>
            <p className="mb-4">2.2. Other users may browse and join Activities posted by you and other users. By joining an Activity, you acknowledge and agree that participation in such Activity is at your own risk.</p>
            <h2 className="text-2xl font-bold mb-4">3. User Content</h2>
            <p className="mb-4">3.1. You retain ownership of any content you post on the App ("User Content"). However, by posting User Content, you grant us a non-exclusive, transferable, sublicensable, royalty-free, worldwide license to use, reproduce, modify, adapt, publish, translate, distribute, and display such User Content in connection with the operation of the App.</p>
            <p className="mb-4">3.2. You agree not to post any User Content that is unlawful, defamatory, libelous, obscene, pornographic, indecent, lewd, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, or otherwise objectionable.</p>
            <h2 className="text-2xl font-bold mb-4">4. Prohibited Conduct</h2>
            <p className="mb-4">4.1. You agree not to engage in any conduct that violates these Terms or any applicable laws or regulations.</p>
            <p className="mb-4">4.2. Prohibited conduct includes, but is not limited to:</p>
            <ul className="list-disc ml-4">
                <li>Posting false, misleading, or fraudulent information.</li>
                <li>Harassing, threatening, or intimidating other users.</li>
                <li>Violating the intellectual property rights of others.</li>
                <li>Using the App for any unlawful purpose.</li>
            </ul>
            <h2 className="text-2xl font-bold mb-4">5. Limitation of Liability</h2>
            <p className="mb-4">5.1. To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
            <ul className="list-disc ml-4">
                <li>Your access to or use of the App.</li>
                <li>Any conduct or content of third parties on the App.</li>
                <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
            </ul>
            <h2 className="text-2xl font-bold mb-4">6. Modification of Terms</h2>
            <p className="mb-4">6.1. We reserve the right to modify these Terms at any time. If we make changes to these Terms, we will notify you by posting the revised Terms on the App. Your continued use of the App following the posting of the revised Terms constitutes your acceptance of such changes.</p>
            {/* <h2 className="text-2xl font-bold mb-4">7. Governing Law</h2>
            <p>7.1. These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.</p> */}
            <h2 className="text-2xl font-bold mb-4">7. Contact Us</h2>
            <p className="mb-4">7.1. If you have any questions about these Terms, please contact us at <a href="mailto:woutvanwonterghem@gmail.com" className="text-primary">woutvanwonterghem@gmail.com</a>.</p>
        </div>
    )
}