import { useEffect, useState } from "react";
import { getCoverImage } from "../utils/watersport";
import { Link } from "react-router-dom";
import { getParticipants } from "../models/event"
import { useTranslation } from "react-i18next";
import Icon from "./Icon";

export default function EventCard({ event }){
    const { t } = useTranslation();
    const [ participants, setParticipants ] = useState([]);

    useEffect(() => {
        getParticipants(event)
            .then(parts => {
                parts = parts.slice(0, 5);
                setParticipants(parts);
            })
    },[event])

    const imageUrl = getCoverImage(event);

    return (
        <Link to={'/events/'+event.id} className="bg-white h-full rounded-2xl shadow flex flex-col justify-between w-full relative">
            <div>
                <div className="absolute w-8 h-8 fill-primary bg-white rounded-full p-1 top-2 right-2 shadow">
                    <Icon name={event.sport}/>
                </div>
                <img src={imageUrl} alt={event.title} className="object-cover h-40 rounded-t-2xl w-full" />
                <div className="p-2">
                    <p>{event.dateString}</p>
                    <p className="font-bold">{event.title}</p>
                    <small className="text-gray-500 whitespace-nowrap">{event.startTime} - {event.endTime}</small>
                </div>
            </div>
            <div className="p-2 pt-0">
                {/* <p>{ t('Attendees') }</p> */}
                <div className="flex h-4 ml-1">
                    {participants.map(part => {
                        if(part.photoURL) return ( <div key={part.uid} className="w-3"><img src={part.photoURL} alt="participant" className="h-4 min-w-4 rounded-full"/></div> )
                        else return ( <div key={part.uid} className="w-3"><div className="h-4 w-4 rounded-full bg-primary-gradient"></div></div> )
                    })}
                </div>
            </div>
        </Link>
    )
}