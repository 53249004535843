import Icon from "./Icon";
import { Link } from "react-router-dom";

export default function ProfileImage({user}){
    return (
        <Link to="/profile" className="w-10 h-10 bg-primary-gradient rounded-full fill-white overflow-hidden">
            {
                user&&user.photoURL?
                (
                    <img src={user.photoURL} alt="Profile" />
                ):(
                    <div className="p-2">
                        <Icon name="profile"/>
                    </div>
                )
            }
        </Link>
    )
}