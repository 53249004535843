'use client'

import Icon from "../components/Icon";
import { useEffect, useState } from "react";
import { getEvent, getJoinedEventIds, getParticipants, joinEvent, leaveEvent, removeEvent } from "../models/event";
import { getCoverImage } from "../utils/watersport";
import Loading from "./Loading";
import { useParams, useNavigate, Link } from 'react-router-dom'
import { UserAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";

export default function Event() {
    const { t } = useTranslation()
    const { user } = UserAuth();
    const params = useParams();
    const navigate = useNavigate();

    const [ event, setEvent ] = useState(null);
    const [ participants, setParticipants ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const [ hasJoined, setHasJoined ] = useState(false);

    useEffect(()=>{
        getEvent(params.id)
            .then(event => {
                setEvent(event)
                // check if user joined
                getJoinedEventIds(user)
                    .then((eventIds) => {
                        if(eventIds.includes(event.id)) setHasJoined(true);
                    })
                // get participant images
                getParticipants(event)
                    .then(parts => {
                        setParticipants(parts)
                    })
                setLoading(false);
            })
            .catch(e => {
                navigate('/events')
            });
    },[params, navigate, user])

    function remove(){
        if(!window.confirm('Are you sure you want to remove this event?')) return;
        removeEvent(event)
            .then(e => {
                navigate(-1);
            });
    }

    async function join(){
        await joinEvent(event, user);
        setHasJoined(true);
        setParticipants([ ...participants, {uid: user.uid, name:user.displayName, photoUrl: user.photoUrl}]);
    }

    function leave(){
        leaveEvent(event,user.uid);
        setHasJoined(false);
        setParticipants(participants.filter(part => part.uid !== user.uid));
    }

    function share(){
        const url = 'https://beyond-boards.org'+window.location.pathname;
        if(navigator.share){
            navigator.share({
                title: event.title,
                url: url,
                text: 'Join me at: '+event.title
            })
            .then(()=>{
                console.log('thanks for sharing')
            })
        }
    }

    if(loading) return (<Loading/>)

    const imageUrl = getCoverImage(event);

    return (
        <div className="relative mb-20">
            <div className="absolute w-full flex justify-between">
                <button className="bg-white rounded-full w-8 h-8 m-2 stroke-gray-500 shadow" onClick={() => navigate(-1)}>
                    <Icon name="back-arrow"/>
                </button>
                <div className="flex">
                    {/* <button id="like-button" className="bg-white rounded-full w-8 h-8 m-2 p-1 fill-gray-500">
                        <Icon name="heart"/>
                    </button> */}
                    {
                        navigator.canShare && (
                            <button onClick={share} id="remove-button">
                                <div className="bg-white rounded-full w-8 h-8 m-2 p-1 stroke-gray-500 shadow">
                                    <Icon name="share"/>
                                </div>
                            </button>
                        )
                    }
                    {
                        event.creator === user.uid&&(
                            <>
                                <Link to={'/events/'+event.id+'/edit'} id="edit-button">
                                    <div className="bg-white rounded-full w-8 h-8 m-2 p-1 stroke-gray-500 shadow">
                                        <Icon name="pencil"/>
                                    </div>
                                </Link>
                                <button onClick={remove} id="remove-button">
                                    <div className="bg-white rounded-full w-8 h-8 m-2 p-1 stroke-red-500 shadow">
                                        <Icon name="bin"/>
                                    </div>
                                </button>
                            </>
                        )
                    }
                </div>
            </div>
            <img src={imageUrl} alt="Event cover" className="h-52 w-full object-cover"/>
            <div className="relative">
                <div className="absolute -top-4 bg-white rounded-t-2xl h-5 w-full"></div>
            </div>
            <div className="px-4 pt-1">
                <div className="flex justify-between items-center mb-2">
                    <div>
                        <p className="font-bold">{event.title}</p>
                        <p className="text-gray-500 text-sm">{event.locationString}</p>
                    </div>
                    <div className="h-10 w-10 fill-gray-500">
                        <Icon name={event.sport}/>
                    </div>
                </div>
                <hr/>
                <div className="flex flex-wrap justify-between">
                    <p>{event.dateString} {event.startTime}-{event.endTime}</p>
                </div>
                {/* <p>{event.location}</p> */}
                <p className="my-4">{event.description}</p>
                <hr/>
                {
                    hasJoined?(
                        <>
                            <Link to={'/chats/'+event.id}>
                                <div className="bg-primary-gradient p-2 rounded w-full mt-4 hover:brightness-125 font-bold text-white text-center">
                                    { t('Join the chat') }
                                </div>
                            </Link>
                            <button onClick={leave} className="bg-red-gradient p-2 rounded w-full mt-4 hover:brightness-125 font-bold text-white">{ t('Leave') }</button>
                        </>
                    ):(
                        <button onClick={join} className="bg-primary-gradient p-2 rounded w-full mt-4 hover:brightness-125 font-bold text-white">{ t('Count me in') }</button>
                    )
                }
                <hr/>
                { !!event.location && (
                    <>
                        <p className="font-bold text-gray-500 my-4">{ t('Location') }</p>
                        <img alt="location" src={'https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/'+event.location.longitude+','+event.location.latitude+',12,0.00,0.00/1000x600@2x?access_token=pk.eyJ1Ijoid291dHZ3IiwiYSI6ImNsa2ZydzBqZTFubnozbHRqdWkzMDlvdHQifQ.Zt44DMvrSnoETzMCE3oOBA'}/>
                    </>
                )}

                <p className="font-bold text-gray-500 my-4">{ t('Attendees') }</p>
                <div className="my-4">
                    {participants.map(part => (
                        <div key={part.uid} className="flex items-center mb-2">
                            <img src={part.photoURL} alt="participant" className="rounded-full h-10 w-10 mr-4"/>
                            <p className="font-bold">{ part.name }</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
