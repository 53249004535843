import './App.css';
import './utils/i18n';
import Home from './pages/Home'
import Chat from './pages/Chat'
import Event from './pages/Event'
import Login from './pages/Login'
import Chats from "./pages/Chats"
import Events from './pages/Events'
import Profile from './pages/Profile'
import AddEvent from './pages/AddEvent'
import Settings from './pages/Settings'
import Register from './pages/Register'
import EditEvent from "./pages/EditEvent"
import EditProfile from './pages/EditProfile'
import MainLayout from './layouts/MainLayout'
import BaseLayout from './layouts/BaseLayout'
import { AuthContextProfider } from './context/AuthContext';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Welcome from './pages/Welcome';
import { TermsAndConditions } from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import GDPR from './pages/GDPR';
import About from './pages/About';

export default function App() {
  return (
    <AuthContextProfider>
      <BrowserRouter>
        <Routes>
          <Route path='welcome' element={<Welcome />} />
          <Route path='about-us' element={<About />} />
          <Route path='terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='gdpr' element={<GDPR />} />
          <Route path='login' element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path="/" element={<BaseLayout />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path='/events' element={<Events />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/profile/edit' element={<EditProfile />} />
              <Route path='/settings' element={<Settings />} />
              <Route path='/events/:id' element={<Event />} />
              <Route path='/events/:id/edit' element={<EditEvent />} />
              <Route path='/events/add' element={<AddEvent />} />
              <Route path='/chats' element={<Chats />} />
            </Route>
            <Route path='/chats/:id' element={<Chat />} />
          </Route>
          {/* <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="blogs" element={<Blogs />} />
            <Route path="*" element={<NoPage />} />
            <Route path="contact" element={<Contact />} />
          </Route> */}
        </Routes>
      </BrowserRouter>
    </AuthContextProfider>
  );
}
