import { db } from "../utils/firebase";
import { addDoc, collection, deleteDoc, doc, endAt, getDoc, getDocs, orderBy, query, setDoc, startAt, updateDoc, where } from "firebase/firestore";
import { geohashQueryBounds } from "geofire-common";

export default class Event{
    constructor(event) {
        this.id = event.id;
        this.coverImage = event.coverImage;
        this.title = event.title;
        this.end = event.end;
        this.start = event.start;
        this.imageUrl = event.imageUrl;
        this.sport = event.sport;
        this.description = event.description;
        this.locationString = event.locationString;
        this.location = event.location;
        this.creator = event.creator;
        this.participants = []

        if(this.start){
            const startDate=new Date(this.start.seconds*1000);
            this.dateString = startDate.toLocaleDateString([],{/*weekday: 'long', */day: 'numeric', month: 'long'})
            this.startTime = startDate.toLocaleTimeString([],{hour: 'numeric', minute: 'numeric'})
        }

        if(this.end){
            const endDate=new Date(this.end.seconds*1000);
            this.endTime = endDate.toLocaleTimeString([],{hour: 'numeric', minute: 'numeric'})
        }
    }
}

export async function getEvent(id){
    const eventRef = doc(db, 'events', id)
    const eventSnap = await getDoc(eventRef);
    if(!eventSnap.exists()) throw new Error('Event not found');
    const data = eventSnap.data();
    data.id=eventSnap.id;
    return new Event(data);
}

export async function getParticipants(event){
    const q = query(collection(db, 'events', event.id, 'participants'));
    const participants = await getDocs(q)
    const parts=[];
    participants.forEach(p => {
        parts.push(p.data());
    })
    return parts;
}

export async function getJoinedEventIds(user){
    const eventsRef = collection(db, 'users', user.uid, 'events');
    const q = query(eventsRef);

    const querySnapshot = await getDocs(q);

    let eventIds=[];
    querySnapshot.forEach(doc => eventIds.push(doc.id));
    return eventIds;
}

export async function getEvents(filters={}){
    const eventsRef=collection(db, "events");

    let queryLogic=[
        eventsRef,
        // orderBy('end'),
        // where('end','>',new Date())
    ]

    if(filters.uid){
        queryLogic.push(where('creator','==',filters.uid));
    }
    if(filters.sport){
        queryLogic.push(where('sport','==',filters.sport));
    }
    if(filters.search){
        queryLogic.push(orderBy('search'))
        queryLogic.push(where('search','>=',filters.search))
        queryLogic.push(where('search','<=',filters.search+'\uf8ff'))
    }

    const promises = [];

    if(filters.location){
        const center = filters.location;
        const radius = filters.radius || 50
        const radiusInM = radius * 1000;

        const bounds = geohashQueryBounds(center, radiusInM);
        for(const b of bounds){
            const q = query(...queryLogic ,orderBy('geohash'), where('geohash','!=',null), startAt(b[0]), endAt(b[1]))
            promises.push(getDocs(q))
        }
    }else{
        queryLogic.push(orderBy('start'))
        const q = query(...queryLogic)
        promises.push(getDocs(q))
    }

    // promises.push(getDocs(query(...[...queryLogic, orderBy('geohash'), where('geohash','!=',null)])));

    const eventSnapshots = await Promise.all(promises);

    const events=[];
    for(const snap of eventSnapshots){
        snap.forEach(doc => {
            if(filters.eventIds && filters.eventIds.length && !filters.eventIds.includes(doc.id)) return;
            const event = doc.data();
            event.id = doc.id;
            events.push(new Event(event))
        })
    }

    return events;
}

export async function storeEvent(event){
    const docRef = await addDoc(collection(db, "events"), event);
    return docRef.id
}

export async function updateEvent(id, event){
    await updateDoc(doc(db, "events", id), event)
    return true
}

export function removeEvent(event){
    return deleteDoc(doc(db, "events", event.id));
}

export async function joinEvent(event, user){
    await setDoc(doc(db, 'events' ,event.id, 'participants', user.uid), { uid: user.uid, name:user.displayName, photoURL: user.photoURL })
    await setDoc(doc(db, 'users', user.uid, 'events', event.id), { joinedAt: new Date() })
    return true;
}

export function leaveEvent(event, uid){
    deleteDoc(doc(db, 'events', event.id, 'participants', uid))
    deleteDoc(doc(db, 'users', uid, 'events', event.id))
}

export async function cleanupOldEvents(){
    const q = query(collection(db, 'events'), where('end', '<', new Date()));
    const oldEvents = await getDocs(q)
    oldEvents.forEach(e => {
        deleteDoc(doc(db,'events',e.id));
        deleteDoc(doc(db,'chats',e.id));
        console.log('removed event '+e.id+' and chats');
    })
    return true;
}