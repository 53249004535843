import { auth } from "../utils/firebase"
import { createContext, useContext, useEffect, useState } from "react"
import { GoogleAuthProvider, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth"

const AuthContext = createContext();

export function AuthContextProfider({children}){
    const [ user, setUser ] = useState(null);

    function emailSignUp(email, password){
        return createUserWithEmailAndPassword(auth, email, password)
    }

    function signUpWithGoogle(){
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider)
    }

    function emailSignIn(email, password){
        return signInWithEmailAndPassword(auth, email, password)
    }

    function logOut(){
        signOut(auth)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        })
        return () => unsubscribe()
    },[user])

    return (
        <AuthContext.Provider value={{ user, emailSignUp, emailSignIn, signUpWithGoogle, logOut }}>{children}</AuthContext.Provider>
    )
}

export function UserAuth(){
    return useContext(AuthContext);
}