import { useEffect, useState } from "react";
import { simplifyString } from '../utils/misc';
import { getEvents, getJoinedEventIds } from "../models/event";
import EventCard from "../components/EventCard";
import SkeletonEventCard from "../components/SkeletonEventCard";
import StickyHeader from '../components/StickyHeader';
import { useSearchParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

export default function Events() {
    const { user } = UserAuth();
    const [ searchParams ] = useSearchParams()
    const [ searchEventIds, setSearchEventIds ] = useState([]);

    const [ events, setEvents ] = useState([{ id:1, skeleton: true },{ id:2, skeleton: true },{ id:3, skeleton: true }]);
    const [ searchString, setSearchString ] = useState('');

    useEffect(() => {
        if(searchParams.get('joined')){
            getJoinedEventIds(user)
                .then(eventIds => setSearchEventIds(eventIds));
        }else{
            setSearchEventIds([]);
        }
    },[searchParams])

    useEffect(() => {
        const simplifiedSearch = simplifyString(searchString);

        const filters={
            uid: searchParams.get('uid'),
            sport: searchParams.get('sport'),
            search: simplifiedSearch,
            eventIds: searchEventIds,
        }

        getEvents(filters)
            .then(events => setEvents(events));
    },[searchString, searchEventIds, searchParams])

    return (
        <div>
            <StickyHeader title="Explore"/>
            <div className="p-2 mb-12">
                {events.map(event => {
                    if(event.skeleton) return (<div key={event.id} className="my-2"><SkeletonEventCard/></div>)
                    else return (<div key={event.id} className="my-2"><EventCard event={event}/></div>)
                })}
            </div>
        </div>
    );
}
