import { useState } from "react";
import { auth } from "../utils/firebase";
import { Outlet, useNavigate } from "react-router-dom";
import LoadingPage from "../pages/Loading";
import { onAuthStateChanged } from "firebase/auth";

export default function RootLayout({ children }) {
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(true)

    onAuthStateChanged(auth, (user) => {
        if(!user){
            setLoading(true)
            navigate('/welcome');
        }
        else if(loading) setLoading(false);
    })

    if(loading) return (
        <LoadingPage/>
    )

    return (
        <Outlet/>
    );
}
