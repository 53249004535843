import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getChatGroups } from "../models/chat";
import { toRelativeTime } from "../utils/misc";
import { UserAuth } from "../context/AuthContext";
import StickyHeader from "../components/StickyHeader";

export default function Chats(){
    const { user } = UserAuth();
    const [ chatGroups, setChatGroups ] = useState([]);

    useEffect(() => {
        getChatGroups(user)
            .then(chatGroups => setChatGroups(chatGroups));
    },[user])

    return (
        <>
            <StickyHeader title="Chat" to="/"/>
            <div className="px-2 pt-2">
                {chatGroups.map(group => (
                    <Link key={group.id} to={'/chats/'+group.id} className="flex mb-4 relative">
                        <img src={group.coverImage} alt="chat head" className="w-10 h-10 rounded-full mr-4"/>
                        <div className="grow">
                            <p className="font-bold">{ group.name }</p>
                            <p className="text-sm">{ group.lastMessageText }</p>
                            <div className="flex flex-col text-right absolute top-0 right-0">
                                <small className="text-gray-500">{ toRelativeTime(group?.lastMessage?.seconds) }</small>
                                {/* <span className="bg-primary rounded-full h-6 w-6 text-white text-center">3</span> */}
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    )
}