import { useRef, useState } from "react";
import { useEffect } from "react"

export default function LocationSearch({ name='location', id='location', value, onChangeLocationString, onChangeLocation }){
    const [ timeoutId, setTimeoutId ] = useState(null);
    const [ locationOptions, setLocationOptions ] = useState([]);
    const [ selectedLocation, setSelectedLocation ] = useState({});
    const [ isSearching, setIsSearching ] = useState(false);
    const componentRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    },[]);

    useEffect(() => {
        if(!isSearching) return
        clearTimeout(timeoutId);
        const newTimeoutId = setTimeout(() => {
                searchLocation();
            }, 500);
        setTimeoutId(newTimeoutId);
    },[value])

    function handleOutsideClick(e){
        if (componentRef.current && !componentRef.current.contains(e.target)) {
            setIsSearching(false)
        }else{
            setIsSearching(true)
        }
    }

    function searchLocation(){
        const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(value)}`;
        fetch(apiUrl)
            .then(r => {
                return r.json();
            })
            .then(json => {
                const locations = json.map(location => {
                    return {
                        name: location.name,
                        extra: location.display_name,
                        coords: {
                            lng: parseFloat(location.boundingbox[2]),
                            lat: parseFloat(location.boundingbox[0]),
                        }
                    }
                })
                setLocationOptions(locations);
            })
    }

    function handleLocationSelect(location){
        setSelectedLocation(location)
        setIsSearching(false);
        onChangeLocationString({target:{value:location.name}})
        onChangeLocation({target:{value:location}})
    }

    return (
        <div className="mb-2 relative" ref={componentRef}>
            <input name={name} id={id} className="input mb-0" value={value} onChange={onChangeLocationString}/>
            {
                (isSearching && !!locationOptions.length) && (
                    <div className="absolute top-14 bg-white rounded border max-h-56 overflow-y-auto w-full">
                        { locationOptions.map((location, idx) => {
                            return (
                                <div key={idx} className="p-2 border-b" onClick={() => handleLocationSelect(location)}>
                                    <p className="font-bold">{location.name}</p>
                                    <p className="text-sm text-gray-500">{location.extra}</p>
                                </div>
                            )
                        }) }
                    </div>
                )
            }
        </div>
    )
}