'use client'

import { getEvents } from "../models/event";
import { useEffect, useState } from "react";
import { sports } from "../utils/watersport";
import EventsList from "../components/EventsList";
import { UserAuth } from "../context/AuthContext";
import ProfileImage from "../components/ProfileImage";
import SportCategory from "../components/SportCategory";
import { useTranslation } from "react-i18next";

export default function Home() {
    const { t } = useTranslation();
    const { user } = UserAuth();
    const [ discoverEvents, setDiscoverEvents ] = useState([]);
    const [ recommendedEvents, setRecommendedEvents ] = useState([]);
    const [ location, setLocation ] = useState([51.5143383, 4.0074612]);

    useEffect(() => {
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition((geolocationPosition) => {
                const { latitude, longitude } = geolocationPosition.coords;
                setLocation([ latitude, longitude ])
            })
        }
    },[])

    useEffect(()=>{
        getEvents()
            .then(events =>{
                setDiscoverEvents(events)
            });
        getEvents({ location: location, radius: 30 })
            .then(events => {
                setRecommendedEvents(events)
            })
    },[location])

    return (
        <div className="min-h-full bg-gray-100">
            <div className="p-4 bg-white shadow sticky top-0 z-10">
                <div className="flex justify-between">
                    <div></div>
                    {/* <p className="mt-2">{ t('Next activity') }: June 20th, 8:25 PM</p> */}
                    <ProfileImage user={user}/>
                </div>
                {/* <input placeholder="search" className="w-full bg-gray-100 rounded-lg p-2 mt-4 outline-none"/> */}
            </div>
            <div className="py-2 mb-14">
                <h2 className="font-bold text-2xl ml-2 mt-4 mb-4">{ t('Discover watersport activities') }</h2>
                <EventsList events={discoverEvents}/>
                <h2 className="font-bold text-2xl ml-2 my-4">{ t('Watersports') }</h2>
                <div className="flex overflow-auto no-scrollbar">
                    { sports.map(sport => <SportCategory key={sport} sport={sport}/>) }
                </div>
                <h2 className="font-bold text-2xl ml-2 my-4">{ t('Nearby activities') }</h2>
                <EventsList events={recommendedEvents}/>
            </div>
        </div>
    );
}
