import { Link } from "react-router-dom";
import Icon from "../components/Icon";
import { useTranslation } from "react-i18next";

export default function SportCategory({ sport }){
    const { t } = useTranslation();
    return (
        <Link to={'/events?sport='+sport}>
            <div className="p-1 fill-primary text-gray-500 w-24">
                <div className="w-16 h-16 mx-auto my-2 bg-white rounded-lg p-3 shadow">
                    <Icon name={sport}/>
                </div>
                <p className="font-bold text-sm text-center">{ t(sport) }</p>
            </div>
        </Link>
    )
}